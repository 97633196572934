export default defineI18nConfig(() => ({
  legacy: false,
  fallbackLocale: 'en',
  datetimeFormats: {
    en: {
      normal: {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      },
      short: {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      },
      month: {
        year: 'numeric',
        month: 'short',
      },
    },
    'en-GB': {
      normal: {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      },
      short: {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      },
      month: {
        year: 'numeric',
        month: 'short',
      },
    },
    uk: {
      normal: {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      },
      short: {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      },
      month: {
        year: 'numeric',
        month: 'short',
      },
    },
    es: {
      normal: {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      },
      short: {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      },
      month: {
        year: 'numeric',
        month: 'short',
      },
    },
  },
}))